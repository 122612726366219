<script setup lang="ts">
import { computed, ref, toRef } from "vue";

const props = defineProps({
  username: {
    type: String,
    required: true,
  },
  avatar: {
    type: String,
    default: null,
  },
  api: {
    type: String,
    default: "beam",
  },
  imageClass: {
    type: String,
  },
  size: {
    type: [Number, String],
    default: "40",
  },
  sizes: {
    type: String,
    default: "",
  },
});

const defaultProfile = computed(
  () =>
    `https://source.boringavatars.com/${props.api}/${props.size}/${props.username.replace(
      /[^a-z0-9]+/i,
      "-"
    )}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`
);

const avatar = toRef(props, "avatar");

const username = computed(() => props.username);
const isError = ref(false);
const handleError = (event: Event) => {
  if (event.type === "error") {
    isError.value = true;
  }
};
</script>

<template>
  <div>
    <nuxt-img
      :src="avatar && !isError ? avatar : defaultProfile"
      @error="handleError"
      :alt="username"
      format="webp"
      class="rounded-full block h-fit w-full max-w-full bg-gray-100 dark:bg-slate-800 object-cover object-center"
      :class="imageClass"
    />
    <slot />
  </div>
</template>
